<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #title>Dados Cadastrais (Indiretas)</template>
        <template #content>
          <div class="p-fluid grid mb-1">
            <div class="field col-12 md:col-12">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  v-model="servidor.servidorNome"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nome">Nome Completo</label>
              </span>
            </div>
            <div class="field col-6 md:col-2">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  type="text"
                  :value="servidor.dataNascimento | formatarData"
                  class="input-text"
                  disabled />
                <label for="nome">Data de Nascimento</label>
              </span>
            </div>
            <div class="field col-6 md:col-2">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  type="text"
                  :value="servidor.cpf | mascaraCpf"
                  class="input-text"
                  disabled />
                <label for="nome">CPF</label>
              </span>
            </div>
            <div class="field col-6 md:col-2">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  type="text"
                  :value="servidor.sexo | formatarSexo"
                  class="input-text"
                  disabled />
                <label for="nome">Sexo</label>
              </span>
            </div>
            <div class="field col-6 md:col-2">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  type="text"
                  :value="servidor.estadoCivilId | formatarEstadoCivil"
                  class="input-text"
                  disabled />
                <label for="nome">Estado Civil</label>
              </span>
            </div>
            <div class="field col-6 md:col-2">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  type="text"
                  :value="servidor.rgNumero"
                  class="input-text"
                  disabled />
                <label for="nome">Identidade</label>
              </span>
            </div>
            <div class="field col-6 md:col-1">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  type="text"
                  :value="servidor.rgUf"
                  class="input-text"
                  disabled />
                <label for="nome">UF</label>
              </span>
            </div>
          </div>

          <div class="p-fluid grid mb-1">
            <div class="field col-12 md:col-2">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  type="text"
                  :value="servidor.entidadeNome"
                  class="input-text"
                  disabled />
                <label for="cpf">Entidade</label>
              </span>
            </div>
            <div class="field col-6 md:col-2">
              <span class="p-float-label">
                <InputText
                  id="sexo"
                  type="text"
                  :value="servidor.matricula"
                  class="input-text"
                  disabled />
                <label for="sexo">Matricula</label>
              </span>
            </div>
            <div class="field col-12 md:col-6">
              <span class="p-float-label">
                <InputText
                  id="raca"
                  v-model="servidor.vinculoNome"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="raca">Vinculo</label>
              </span>
            </div>
          </div>
          <div class="p-fluid grid mb-1">
            <div class="field col-6 md:col-2">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  type="text"
                  :value="servidor.celular"
                  class="input-text"
                  disabled />
                <label for="cpf">Celular</label>
              </span>
            </div>
            <div class="field col-12 md:col-5">
              <span class="p-float-label">
                <InputText
                  id="sexo"
                  type="text"
                  :value="servidor.email"
                  class="input-text"
                  disabled />
                <label for="sexo">E-Mail</label>
              </span>
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import MatriculaService from '@/service/MatriculaService.js'
import { matriculaStore } from '@/stores/matricula'

export default {
  setup() {
    const store = new matriculaStore()
    return { store }
  },
  data() {
    return {
      matriculasList: [],
      matricula: {},
      loading: false,
      servidor: {},
    }
  },

  watch: {
    'store.matriculaSelecionada': function () {
      this.carregarDados()
    },
  },

  created() {
    this.matriculaService = new MatriculaService(this.$http)
  },

  mounted() {
    this.carregarDados()
  },

  methods: {
    carregarDados() {
      this.loading = true
      if (
        this.store.matriculaSelecionada.entidadeNome !== 'SEAD 1' &&
        this.store.matriculaSelecionada.entidadeNome !== 'SEAD 2'
      ) {
        this.matricula = this.store.matriculaSelecionada
        this.getServidor(this.store.matriculaSelecionada.id)
      }
      this.limpar()
    },

    limpar() {
      this.loading = false
    },
    getServidor(matricula) {
      this.matriculaService
        .getMatriculaServidorLogado(matricula)
        .then((res) => {
          this.servidor = res
        })
    },
  },
}
</script>

<style>
.input-text {
  width: 100%;
  color: black;
}
</style>
