import { render, staticRenderFns } from "./ServidorDadosCadastrais.vue?vue&type=template&id=2103a09d"
import script from "./ServidorDadosCadastrais.vue?vue&type=script&lang=js"
export * from "./ServidorDadosCadastrais.vue?vue&type=script&lang=js"
import style0 from "./ServidorDadosCadastrais.vue?vue&type=style&index=0&id=2103a09d&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports